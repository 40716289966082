<template>
    <v-hover v-slot:default="{hover}">
        <v-card
            class="card off mx-2 mb-1" :class="{ 'card_hover' : hover}"
            :elevation="hover ? 4 : 2" style="cursor: pointer;">
            <v-card-text class="card-state pa-1" :class="item.state">
                <!--HEADER-->
                <v-row no-gutters justify="center" v-if="true">
                    <v-col cols="8" class="pb-0" >
                        <v-icon class="mb-1 pr-1" color="gold" small>mdi-account-tie</v-icon>
                        <span class="font-weight-bold gold--text">{{ item.seller }}</span>
                    </v-col>
                    <v-col cols="4"
                           class="font-weight-bold"
                           align="right"
                    >
                        <span class="rounded gold white--text px-3">{{ item.amount }}</span>
                    </v-col>

                    <v-col cols="12">
                        <v-divider class="gold lighten-1"></v-divider>
                    </v-col>

                    <v-col cols="4">
                        <span>VS: {{item.vs}}</span>
                    </v-col>
                    <v-col cols="8" align="right">
                        <span>{{g_parseBankAccount(item.bank_account)}}</span>
                    </v-col>
                </v-row>
            </v-card-text>

        </v-card>
    </v-hover>
</template>

<script>
export default {
    name: "SalaryReportDetailCard",
    props: {
        item: Object,
        keys: undefined,
        state: undefined
    }
}
</script>